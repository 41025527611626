import React, {  useState } from "react";
import { ReactComponent as CheckBoxActive } from "../../../../assets/icons/checkbox-active.svg";
import { ReactComponent as CheckBoxDisabled } from "../../../../assets/icons/checkbox-inactive.svg";
// import moment from "moment";
import propTypes from "prop-types";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config";
import { applyNow } from "../apiFunctions";
import { useSelector , useDispatch } from "react-redux";
import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import { useQueryClient } from "react-query";
import { clearCompareSchoolList } from "../../../../redux/features/schools/SchoolSlice";
import {apirequest_schoolsAPI} from "../../../../API/api";
import {setCompareSchoolList } from "../../../../redux/features/schools/SchoolSlice";

// import { loginDetails } from "../../../../redux/features/login/LoginSlice";
// import { useSelector } from "react-redux";

function ProgrammesTable({ programData , schoolID}) {
  
  const [programId,setProgramId]= useState(null);

  // const LoginDetails = useSelector(loginDetails);
  // const [isSelectedForCompare, setIsSelectedForCompare] = useState(false);
  const LoginDetails = useSelector(loginDetails);
  const queryClient = useQueryClient();
  let history = useHistory();
  const dispatch = useDispatch();
  const compareSchoolList = useSelector(state=> state.school.compareListIds);

  const addToComp = () => {
    dispatch(clearCompareSchoolList());
    return apirequest_schoolsAPI({
      url: `${endpoint.SchoolCompare.addr}`,
      method: "post",
 
      headers: {
        "access-token": LoginDetails.token
      },
      data: {
        schoolDetails:[
          {schoolId:schoolID,programId:programId}
        ]
      }
    });
  };
  useQuery(
    [endpoint.SchoolCompare.key,schoolID,programId],
    addToComp,
    {
      enabled: !!schoolID && !!programId,
      retry:false,
      onSuccess: async res => {
        await dispatch(setCompareSchoolList(res.data.data));
        history.push("/school/compare");

      }
    }
  ); 
  const { refetch: callApplyNowApi } = useQuery(
    endpoint.applyNow.key,
    () => applyNow({ 
      programName : programData.programs,
      schoolProgramMappingId : programData.schoolProgramCourseMapping,
      schoolId:schoolID
    },
    LoginDetails.token
    ),
    {
      enabled: false,
      onSuccess : ()=>{
        queryClient.invalidateQueries(endpoint.getSchoolDetails.key);
      },
      // onClick :  window.open(programData.applicationLink, "_blank", "noopener,noreferrer"),
    }
  );

  const handleApplynow = ()=>{
    window.open(programData.applicationLink, "_blank", "noopener,noreferrer");
    callApplyNowApi();
    

  };

  const handleCompare=async (programID)=>{
    // setIsSelectedForCompare(!isSelectedForCompare);
    // await dispatch(clearCompareSchoolList());
    // let payload = {
    //   schoolId: schoolID,
    //   programId:programData.schoolProgramCourseMapping
    // };
    // await dispatch(addToCompare(payload));
    // await history.push("/school/compare");
    setProgramId(programID);
  };

  const isSelectedToCompare = compareSchoolList.some(element => {
    if (element.programId === programId) {
      return true;
    }
    return false;
  });
  return (
    <div className="mb-16">
      <div className="shadow-outer rounded-2xl mb-16 ">
        {/*Table Header*/}
        <div className="flex items-center justify-between px-8 py-6 shadow-outer ">
          <div className="flex items-center space-x-4">
            {/* <p className="font-medium text-lg text-secondary-600 ">
              (PGDP) Post Graduate Diploma Programme
            </p>
            <p className="bg-secondary-200 text-secondary-600 px-3 py-2 rounded-2xl text-sm capitalize">Full Time</p> */}
          </div>
          <div className="flex items-center">
            <div
              className="mr-2 cursor-pointer "
              onClick={() => handleCompare(programData?.schoolProgramCourseMapping)}
            >
              {isSelectedToCompare ? <CheckBoxActive /> : <CheckBoxDisabled />}
            </div>
            <p className="text-lg">Compare</p>
          </div>
        </div>

        {/*Table body */}
        <div className="px-8 py-10 flex items-center justify-between text-center ">
          <div>
            <p className="text-primary-900 font-semibold text-2xl ">
              {programData?.imsClassification}
            </p>
            <p className="text-secondary-600 font-normal text-xl ">Ranking</p>
          </div>
          <div>
            <p className="text-primary-900 font-semibold text-2xl ">
              {programData?.batchSize}
            </p>
            <p className="text-secondary-600 font-normal text-xl ">Intake</p>
          </div>
          <div>
            <p className="text-primary-900 font-semibold text-2xl ">
              {programData?.fees/100000}
            </p>
            <p className="text-secondary-600 text-xl">LPA</p>
          </div>
          <div>
            <p className="text-primary-900 font-semibold text-2xl ">
              {programData?.duration}yrs
            </p>
            <p className="text-secondary-600 font-normal text-xl ">
              Course duration
            </p>
          </div>
          <div>
            {programData?.applicationLink && <button
              onClick={handleApplynow}
              className="py-5 mb-4 w-[304px] text-white rounded-2xl bg-primary-200"
            >
              Apply Now
            </button>}
          </div>
        </div>
      </div>

      {/* Accepted Exam details comes here  */}
      <section className="space-y-7 ">
        <h1 className="font-ibm font-medium text-2xl text-secondary-600">
          Exams Accepted
        </h1>
        <div className="space-x-4">
          {programData?.examAccepted?.map((item) => (
            <span
              key={item}
              className="mr-2 bg-secondary-200 text-secondary-600 px-3 py-2 rounded-2xl text-sm capitalize"
            >
              {item}
            </span>
          ))}
        </div>
      </section>
    </div>
  );
}

ProgrammesTable.propTypes = {
  programData: propTypes.object,
  schoolID : propTypes.string
};

export default ProgrammesTable;
