import React, { useState } from "react";
import cancelIcon from "../../../../assets/icons/cancel.svg";
import DropDownMultiselect from "./DropdownMultiSelectForFiter";
import tick from "../../../../assets/icons/tick.svg";
import WithModal from "../../global/WithModal/WithModal";
import propTypes from "prop-types";
import {Slider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import closeIcon from "../../../../assets/icons/close-black.svg";
import thumbIcon from "../../../../assets/icons/slider-point.svg";
import Icon from "../../global/icons/Icon";
import "./Slider.css";

import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config";
import { apirequest_schoolsAPI } from "../../../../API/api";
import { useSelector } from "react-redux";
import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import Loader from "../../global/loader/Loader";
const useStyles = makeStyles(theme => ({
  root: {
    width: 200
  },
  margin: {
    height: theme.spacing(3)
  },

  rail: {
    background: "#ECF3F5;",
    height:3,
    opacity :2
  },
  track: {
    background: "#00ABFB;",
    height :3
  },
}));

const FilterFunctionality = ({closeModal , setMappedSchoolData}) => {


  const savedFilters =[
    {id:1, item: "A+"},
    {id:2 , item : "6-7 LPA"},
    {id:3 , item :"SNAP"}
  ];

  const LoginDetails = useSelector(loginDetails);
  const courseId = useSelector(state=>state.login.courseId);

  const  [lpaRange , setLPARange] = useState([]);
  const [feesRange , setFeesRange] = useState([]);

  const [finalLpa , setFinalLpa] = useState([]);
  const [rangeOfFees , setRangeofFees] = useState([]);


  const {data : filterSchoolData , isSuccess , isError, isLoading} = useQuery(endpoint.getSchoolFiltersData.key ,() =>apirequest_schoolsAPI({
    url : endpoint.getSchoolFiltersData.addr,
    method : "POST",
    headers : {
      "access-token": LoginDetails.token
    },
    data  : {
      courseId
    }
  }),{
    select : (res)=>res.data.data[0],
    onSuccess : (res)=>{
      setFinalLpa([Math.min(...res.AverageSalary) , Math.max(...res.AverageSalary)]);
      setRangeofFees([Math.min(...res.Fees) , Math.max(...res.Fees)]);
      setFeesRange([Math.min(...res.Fees) , Math.max(...res.Fees)]);
      setLPARange ([Math.min(...res.AverageSalary) , Math.max(...res.AverageSalary)]);
    }
  }
  );
 
  const [filters,seTFilters] = useState(savedFilters);
  const [payloadForFilterSchooldata , setPayloadForFilterSchoolData] = useState({
    examAccepted : [],
    classifications: [] ,
    location : []
  });
 
  const feeRange = [
    {
      value :isSuccess ? Math.min(...filterSchoolData?.Fees) : 0,
      label: isSuccess ? Math.ceil( Math.min(...filterSchoolData?.Fees) /100000) + "LPA" : "LPA"
    },{
      value: isSuccess ? Math.max(...filterSchoolData?.Fees) : 100,
      label: isSuccess ? Math.ceil( Math.max(...filterSchoolData?.Fees) /100000) + "LPA" : "LPA"
    }
  ];


  const lpaData =[
    {
      value : isSuccess ? Math.min(...filterSchoolData?.AverageSalary) : 0,  
      label:  isSuccess ? Math.ceil(Math.min(...filterSchoolData?.AverageSalary) /100000) + "LPA" : "LPA"
    },
    {
      value :isSuccess ? Math.max(...filterSchoolData?.AverageSalary) : 100,
      label : isSuccess ? Math.ceil(Math.max(...filterSchoolData?.AverageSalary) /100000) + "LPA" : "LPA"
    }
  ];

  const classes= useStyles();
  const ThumbComponent =(props)=>{
    return (
      <span {...props}>
        <Icon src={thumbIcon} className ="shadow-none"/>
      </span>
    );
  };
  const ThumbComponent2 =(props)=>{
    return (
      <span {...props}>
        <Icon src={thumbIcon} className ="shadow-none"/>
      </span>
    );
  };


  const removeFilters = (e) => {
    let remove = e.target.getAttribute("removeFilter");
    seTFilters(filters.filter(items=>items.subItem!==remove));
  };


  // function for  handling exam Name =============>

  function handleExamNames(examName) {

    setPayloadForFilterSchoolData ((prevData )=>{
      return {
        ...prevData , 
        "examAccepted" : prevData.examAccepted?.includes(examName) ? 
          prevData.examAccepted.filter(item =>item!= examName) : [...prevData.examAccepted , examName]
      };
    });
  }

  const {refetch} = useQuery(endpoint.filterSchoolData.key , ()=>apirequest_schoolsAPI({
    method : "POST",
    url : endpoint.filterSchoolData.addr ,
    headers : {
      "access-token": LoginDetails.token
    },
    data : {
      "classifications" :payloadForFilterSchooldata.classifications.length ? payloadForFilterSchooldata.classifications : [""],
      "examAccepted" : payloadForFilterSchooldata.examAccepted.length ? payloadForFilterSchooldata.examAccepted : [""],
      "location" : payloadForFilterSchooldata.location.length ? payloadForFilterSchooldata.location : [""],
      "AverageSalary" : JSON.stringify(lpaRange) === JSON.stringify(finalLpa) ? [""] : finalLpa ,
      "Fees" : JSON.stringify(rangeOfFees) === JSON.stringify(feesRange) ? [""] :   rangeOfFees,
      courseId ,
    }
  }),{
    enabled : false,
    select : (res)=>res?.data.data ,
    onSuccess : (res)=> {
      setMappedSchoolData(()=>{
        return (
          res.map(parentArr=>parentArr.schoolProgramData.map(childArr=>{
            return {
              schoolId: parentArr.schoolId,
              schoolProgramCourseMapping :  childArr.schoolProgramCourseMapping,
              batchSize : childArr.batchSize,
              fees: childArr.fees/100000,
              avgSalary : (childArr.avgSalary)/100000, //============>.replace(/,/g, "") value changed from string to number <=======
              cutOffId: childArr.cutOffId,
              isSchoolLiked : childArr.isSchoolLiked,
              programs : childArr.programs,
              imsClassification :  childArr.imsClassification,
              examAccepted : childArr.examAccepted,
              schoolName : parentArr.schoolName,
              courses : childArr.courses
            };
          })).flat()
        );
      });
      closeModal();
    }
  });

  return (
    <div className="w-[820px] inline-block overflow-visible rounded-2xl text-left align-middle transition-all transform bg-white opacity-100 scale-100">
      <header className="flex justify-between py-3 shadow-outer px-5 ">
        <p className="font-medium text-lg">Filters</p>
        <img className="pl-3 cursor-pointer" src={cancelIcon} onClick={closeModal} loading="lazy"/>
      </header>    
      <div className=" space-y-3 divide-y-2 divide-primary-400">
        {/* <div className=""> */}
        <div className="px-5 pt-5 flex space-x-5 ">

          <p className="text-sm">Previously Saved Filters :</p>
          <div className="flex space-x-5">
            {filters.map((data)=>(
              <div key={data.id} className="shadow-outer flex space-x-4 rounded-xl px-2 py-2 -mt-2">
                <span>{data.item}</span>
                <img src={closeIcon}  onClick={removeFilters} className="cursor-pointer" loading="lazy"/>
              </div>
            ))}
          </div>
        </div>
        {isLoading && <Loader/>}
        {isError && <p>Error while fetching Data , Please try again !</p>}
        {isSuccess&& <section>
          <div className="flex divide-x-2 divide-primary-400 px-5 py-5 w-[100%]">
            <div className="mr-4 w-[30%]">
              <p className="font-medium text-lg">Classification</p>
              <DropDownMultiselect 
                placeholder = "Search Classification" 
                selectedValues={payloadForFilterSchooldata}
                setSelectedValue={setPayloadForFilterSchoolData} 
                options={filterSchoolData?.classifications}
                keyValue = "classifications"
              />
            </div>
            <div className="pl-5 h-auto  w-[70%]">
              <p className="font-medium text-lg ml-2">Exam accepted</p>
              <div className="grid grid-cols-4 justify-start text-right">
                {filterSchoolData?.ExamAccepted.map((data)=>( 
                  <div 
                    onClick={(e)=>handleExamNames(data , e)} 
                    key ={data} 
                    name = "examAccepted"
                    className=" w-28 m-2 justify-between cursor-pointer rounded-xl shadow-outer flex  px-3 py-2 my-1"
                  >
                    <span>{data}</span>
                    {payloadForFilterSchooldata.examAccepted.includes(data) && <img src={tick} className="" /> }
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div> */}
          <div className="flex divide-x-2 divide-primary-400 px-5 py-5 w-[100%]">
            <aside className="pr-3 w-[30%] mr-4">
              <p className="font-medium text-lg">Location</p>
              <DropDownMultiselect 
                placeholder="Enter Location" 
                selectedValues={payloadForFilterSchooldata} 
                setSelectedValue={setPayloadForFilterSchoolData} 
                options={filterSchoolData?.location}
                keyValue="location"
              />
            </aside>
            <aside className="pl-5 space-y-2 w-[67%]">
              <div className="space-y-3">
                <p className="font-medium text-lg">Average Salary</p>
                <div className="w-[400px] pl-5">
                  <Slider 
                    classes={{
                      track :classes.track,
                      rail: classes.rail,
                      thumb :classes.thumb
                    }}
                    value ={finalLpa}               
                    ThumbComponent ={ThumbComponent}
                    valueLabelDisplay="on"
                    marks={lpaData} 
                    min= {Math.min(... filterSchoolData?.AverageSalary)}    
                    max = {Math.max(...filterSchoolData?.AverageSalary)}         
                    onChange={(event, value) => setFinalLpa(value)}       
                  />
                </div>
              </div>
              <div className="space-y-3">
                <p className="font-medium text-lg">Fee Range</p>
                <div className="w-[400px] pl-5"><Slider 
                  classes={{
                    track :classes.track,
                    rail: classes.rail,
                    thumb :classes.thumb
                  }}
                  value ={rangeOfFees}               
                  ThumbComponent ={ThumbComponent2}
                  valueLabelDisplay="on"
                  marks={feeRange}    
                  min={Math.min(...filterSchoolData?.Fees)}
                  max= {Math.max(...filterSchoolData?.Fees)}             
                  onChange={(event, value) => setRangeofFees(value)}       
                /></div>
              </div>
            </aside>
          </div>
        </section>}
      </div>
      <footer className="flex justify-end">
        <button onClick={()=> {refetch(); }} className="bg-primary-200 text-white px-5 mr-5 py-2 text-center rounded-xl">Apply</button>
      </footer>
    </div>
  );
};


FilterFunctionality.propTypes ={
  closeModal:propTypes.any,
  children : propTypes.any,
  open :propTypes.any,
  value : propTypes.any ,
  setMappedSchoolData : propTypes.func
};
export default WithModal(FilterFunctionality) ;
