import React, { useState } from "react";
// import DropDown from "../../components/web/global/dropDown/DropDown";
import propTypes from "prop-types";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  cerebry_apirequest,
  loginAPI_apirequest,
  prepare_apirequest,
} from "../../API/api";
import { endpoint } from "../../API/config";
import Accordian from "../../components/web/global/accordian/Accordian";
import Loader from "../../components/web/global/loader/Loader";
import Search from "../../components/web/global/search/Search";
import ProctoredTest from "../../components/web/Test/ProctoredTest/ProctoredTest";

import {
  clearChannelId,
  loginDetails,
  setChannelId,
  setIsMentor,
} from "../../redux/features/login/LoginSlice";

import { setCurrentHeaderData } from "../../redux/features/header/headerSlice";

import { useHistory } from "react-router-dom";

const CEREBRY_ERROR =
  "We could not reach our servers to fetch Benchmarking Test levels and to enable the Revise with AI feature. Please try again later.";

function Test({ studentImsPin, parentId, id, courseId, variantId }) {
  const login = useSelector(loginDetails);
  const moduleId = useSelector((state) => state.header.headerId);
  const course_name = useSelector((state) => state.login.course_name);

  const history = useHistory();

  const dispatch = useDispatch();

  const [filteredPrepareList, setFilteredPrepareList] = useState([]);
  const [isHeaderOutdated, setIsHeaderOutdated] = useState(false);
  const [onlyAssets, setOnlyAssets] = useState(false);

  // Prepare api calls
  const fetchPrepareList = () => {
    setIsHeaderOutdated(false);
    return prepare_apirequest({
      url: `${endpoint.navigationPathList.addr}`,
      method: "post",

      headers: {
        "access-token": login.token,
      },
      data: {
        studentImsPin,
        courseId,
        variantId,
        parentId,
        id,
      },
    });
  };

  const fetchTestCerebryData = () => {
    return cerebry_apirequest({
      url: `${endpoint.testCerebryData.addr}`,
      method: "post",

      headers: {
        "access-token": login.token,
      },
      data: {
        studentImsPin,
        courseId,
        variantId,
        parentId,
        id,
      },
    });
  };

  const isRequiredPayloadAvailable =
    !!studentImsPin && !!courseId && !!variantId && !!parentId && !!id;

  const fetchHeaderOnCourseChange = () => {
    return loginAPI_apirequest({
      url: `${endpoint.moduleData.addr}`,
      method: "post",

      headers: {
        "access-token": login.token,
      },
      data: {
        studentImsPin: studentImsPin,
        course_id: courseId,
        course_name,
        variant_id: variantId.toString(),
        myImsCourseId: login.lastloggedInMyImsCourseId?.toString(),
      },
    });
  };

  const { mutate } = useMutation({
    mutationKey: [endpoint.moduleData.key, courseId, course_name, variantId],
    mutationFn: () => fetchHeaderOnCourseChange(),
    onSuccess: (res) => {
      // condition added so that if there are no channeldata present then clear the previous stored channelId
      Object.keys(res?.data?.data?.channelData).length > 0
        ? dispatch(setChannelId(res?.data?.data?.channelData?.moduleId))
        : dispatch(clearChannelId());
      dispatch(setIsMentor(res?.data?.data?.isMentor));
      dispatch(setCurrentHeaderData(res?.data?.data?.headerModuleData));
      history?.push("/");
    },
    onError: () => {
      toast("Error fetching module data. try again later");
    },
  });

  const {
    data: prepareList,
    isLoading,
    isFetching,
    isError,
    error,
    refetch: prepareListRefetch,
  } = useQuery(
    [
      endpoint.navigationPathList.key,
      studentImsPin,
      courseId,
      variantId,
      id,
      parentId,
    ],
    fetchPrepareList,
    {
      enabled: isRequiredPayloadAvailable,
      select: (data) => data.data.data[0],
      onSuccess: (res) => {
        setFilteredPrepareList(res?.children);
        const isAsset = (asset) => "assetId" in asset;
        setOnlyAssets(res.children.every(isAsset));
      },
      retry: false,
      onError: (error) => {
        if (error?.response?.data?.error === "HEADER_MODULE_EXPIRED") {
          setIsHeaderOutdated(true);
        } else {
          setIsHeaderOutdated(false);
        }
      },
    }
  );

  const { data: testCerebryData } = useQuery(
    [
      endpoint.testCerebryData.key,
      studentImsPin,
      courseId,
      variantId,
      id,
      parentId,
    ],
    fetchTestCerebryData,
    {
      enabled: isRequiredPayloadAvailable,
      select: (data) => data?.data?.data,
      onError: (error) => {
        if (error?.message === CEREBRY_ERROR) {
          toast(CEREBRY_ERROR);
        }

        if (error?.response?.data?.error === "TEST_DETAILS_FETCH_ERROR") {
          toast(CEREBRY_ERROR);
        }
      },
      retry: false,
    }
  );

  if (isLoading || isFetching) {
    return <Loader />;
  }

  if (isHeaderOutdated) {
    return (
      <div className=" px-14 py-8 font-ibm flex flex-col gap-2 justify-center items-center">
        <p className="text-lg font-medium text-center">
          We have added new content to this page.
          <br /> Click the &quot;Refresh&quot; button to see the updates and
          navigate back to this page.
        </p>
        <button
          className="bg-[#00ABFB] text-white px-5 py-2.5 rounded-md"
          onClick={mutate}
        >
          Refresh
        </button>

        <p className="text-lg font-medium text-center">
          If you are still seeing this message after refreshing, please submit a
          support ticket{" "}
          <a
            className="text-[#00ABFB] underline"
            href="https://support.imsindia.com/support/home"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
        </p>
      </div>
    );
  }

  if (isError) {
    if (error.response?.status === 404)
      return (
        <h1 className="h-full flex items-center justify-center">
          No records found
        </h1>
      );
  } else
    return (
      <div className=" px-14 py-8 font-ibm">
        <div className=" w-full flex justify-between ">
          {/* <DropDown text="Show" /> */}
          {prepareList && !onlyAssets && (
            <div className="ml-auto">
              <Search
                data={prepareList.children}
                setFunction={setFilteredPrepareList}
              />
            </div>
          )}
        </div>
        {prepareList &&
        filteredPrepareList &&
        filteredPrepareList.length > 0 &&
        !onlyAssets ? (
          filteredPrepareList.map(
            (child) =>
              !("assetId" in child) && (
                <Accordian
                  key={child.learningPathId}
                  data={child}
                  moduleId={moduleId}
                  courseId={courseId}
                  prepareListRefetch={prepareListRefetch}
                  isTestModule={true}
                  testCerebryData={testCerebryData}
                />
              )
          )
        ) : (
          <ProctoredTest
            testModule={true}
            data={prepareList.children}
            parentId={prepareList.learningPathId}
            testCerebryData={testCerebryData}
          />
        )}
      </div>
    );
}

Test.propTypes = {
  children: propTypes.string,
  studentImsPin: propTypes.string,
  parentId: propTypes.string,
  id: propTypes.string,
  courseId: propTypes.string,
  variantId: propTypes.string,
};

export default Test;
