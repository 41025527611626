import localforage from "localforage";
// import { clearLoginData } from "../redux/features/login/LoginSlice";
import config from "./config";
import axios from "axios";
// import { useDispatch } from "react-redux";

const clearData = () => {
  // let dispatch = useDispatch();
  localStorage.clear();
  localforage.clear();
  // dispatch(clearLoginData);
};

const onError = (error) => {
  Promise.reject(error.response).catch((err) => {
    // 
    if (err?.status === 401) {
      clearData();
      window.location.href = "/";
      return;
    }
  });

  return Promise.reject(error);
};
// optionaly add base url
const client = axios.create({ baseURL: config.staging });

export const apirequest = ({ ...options }) => {
  client.defaults.headers.common.Authorization = "Bearer";

  const onSuccess = (response) => response;

  return client(options)
    .then(onSuccess)
    .catch((err) => onError(err));
};

const liveAPI_client = axios.create({ baseURL: config.staging_LIVEAPI });

export const apirequest_liveAPI = ({ ...options }) => {
  liveAPI_client.defaults.headers.common.Authorization = "Bearer";

  const onSuccess = (response) => response;
  const onErrorFunc = (error) => {
    return Promise.reject(error.response);
  };

  return liveAPI_client(options).then(onSuccess).catch(onErrorFunc);
};

//For Login apis
const login_client = axios.create({ baseURL: config.staging_LOGINAPI });

export const loginAPI_apirequest = ({ ...options }) => {
  login_client.defaults.headers.common.Authorization = "Bearer";

  const onSuccess = (response) => response;
  // const onError = error => {
  //   if(error["message"]==="Request failed with status code 401"){
  //     window.location.href="/";
  //     return;
  //   }
  //   return Promise.reject(error.response);
  // };

  return login_client(options)
    .then(onSuccess)
    .catch((err) => onError(err));
};
//For Admin Login apis
const login_admin = axios.create({ baseURL: config.staging_LOGINAPI });

export const adminLoginAPI_apirequest = ({ ...options }) => {
  login_client.defaults.headers.common.Authorization = "Bearer";

  const onSuccess = (response) => response;
  const onError = (error) => Promise.reject(error.response);

  return login_admin(options).then(onSuccess).catch(onError);
};

//For Home Page apis
const home_client = axios.create({ baseURL: config.staging_HOMEAPI });

export const homeAPI_apirequest = ({ ...options }) => {
  home_client.defaults.headers.common.Authorization = "Bearer";

  const onSuccess = (response) => response;

  return home_client(options)
    .then(onSuccess)
    .catch((err) => onError(err));
};

const prepare_client = axios.create({ baseURL: config.staging_PREPARE });

export const prepare_apirequest = ({ ...options }) => {
  home_client.defaults.headers.common.Authorization = "Bearer";

  const onSuccess = (response) => response;
  return prepare_client(options)
    .then(onSuccess)
    .catch((err) => onError(err));
};
const cerebry_client = axios.create({
  baseURL: config.staging_PREPARE,
  timeout: 10000,
  timeoutErrorMessage:
    "We could not reach our servers to fetch Benchmarking Test levels and to enable the Revise with AI feature. Please try again later.",
});

export const cerebry_apirequest = ({ ...options }) => {
  home_client.defaults.headers.common.Authorization = "Bearer";

  const onSuccess = (response) => response;
  return cerebry_client(options)
    .then(onSuccess)
    .catch((err) => onError(err));
};

//For schools apis
const schools_client = axios.create({ baseURL: config.staging_SCHOOLSAPI });

export const apirequest_schoolsAPI = ({ ...options }) => {
  schools_client.defaults.headers.common.Authorization = "Bearer";

  const onSuccess = (response) => response;
  // const onError = error => {
  //   if(error["message"]==="Request failed with status code 401"){
  //     window.location.href="/";
  //     return;
  //   }
  //   return Promise.reject(error.response);
  // };

  return schools_client(options)
    .then(onSuccess)
    .catch((err) => onError(err));
};

// for mentor dashboard apis

const apirequest_MYPLAN = axios.create({ baseURL: config.staging_MYPLAN });

export const apirequest_myplan = ({ ...options }) => {
  apirequest_MYPLAN.defaults.headers.common.Authorization = "Bearer";

  const onSuccess = (response) => response;
  const onErrorFunc = (error) => {
    return Promise.reject(error.response);
  };

  return apirequest_MYPLAN(options).then(onSuccess).catch(onErrorFunc);
};
