import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import loginImage from "../../../assets/images/loginImage.png";
import imslogo from "../../../assets/icons/ims-logo.svg";
// import maskImage from "../../../assets/images/maskImage.png";
import CancelIcon from "../../../assets/icons/cancel-icon-rounded.svg";
import { setCurrentHeaderData } from "../../../redux/features/header/headerSlice";
// import {Link} from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Navigation, Autoplay, Pagination, Virtual } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";
import "../PreLogin/login/swiper.css";
SwiperCore.use([Navigation, Autoplay, Pagination, Virtual]);
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { endpoint } from "../../../API/config";
import { loginAPI_apirequest } from "../../../API/api";
import {
  changeCourse,
  saveLoginDetails,
  loginDetails,
} from "../../../redux/features/login/LoginSlice";
import Loader from "../global/loader/Loader";
import OtpModalTrigger from "../PreLogin/login/OtpModalTrigger";
import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";

const swiperData = [
  {
    id: 1,
    title: "Grow your career with quality education",
    para: "Let IMS take care of your preparationas it is the key to your Success",
  },
  {
    id: 2,
    title: "Grow your career with quality education",
    para: "Let IMS take care of your preparationas it is the key to your Success",
  },
  {
    id: 3,
    title: "Grow your career with quality education",
    para: "Let IMS take care of your preparationas it is the key to your Success",
  },
];

const Login = () => {
  const dispatch = useDispatch();
  const LoginDetails = useSelector(loginDetails);
  let history = useHistory();
  const [isLoginClicked, setIsLoginClicked] = useState(false);
  // const [userName, setUserName] = useState("");
  // const [password, setPassword] = useState("");
  // const [isLoginError, setIsLoginError] = useState(false);
  // const [isRegistration, setIsRegistration] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isgetOTPClicked, setIsgetOTPClicked] = useState(false);
  const [mobileNumberOrEmail, setMobileNumberOrEmail] = useState("");
  const [isSaveandContinueClicked, setIsSaveandContinueClicked] =
    useState(false);
  const [verificationDetails, setVerificationDetails] = React.useState();

  // const handleEnter = event => {
  //   if (event.key.toLowerCase() === "enter") {
  //     const form = event.target.form;
  //     const index = [...form].indexOf(event.target);
  //     form.elements[index + 1].focus();
  //     event.preventDefault();
  //   }
  // };
  const getLoginDetails = (userName, password) => {
    return loginAPI_apirequest({
      url: `${endpoint.studentLoginCheck.addr}`,
      method: "post",
      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentemail: userName.includes("@") ? userName : "",
        studentimspin: userName.includes("@") ? "" : userName,
        studentpassword: password,
        loginmode: "1",
        platform: "web",
      },
    });
  };

  const { isLoading: isLoginLoading } = useQuery(
    [endpoint.studentLoginCheck.key],
    () => getLoginDetails,
    {
      retry: false,
      enabled: !!isLoginClicked,
      onSuccess: (res) => {
        let data = res.data.data;
        dispatch(saveLoginDetails(data));
        let courseIdNVariantId =
          data.lastLoggedInCourseId.toString() +
          "_" +
          data.lastLoggedInVariantId.toString();
        let courseId = data.lastLoggedInCourseId.toString();
        let name = data.lastloggedInCourseName;
        let variantId = data.lastLoggedInVariantId;
        dispatch(
          changeCourse({ courseIdNVariantId, courseId, name, variantId })
        );
        // TODO! Replace Local storage with cookies
        let loginInfo = {
          adminemail: res.data.data.studentEmail
            ? res.data.data.studentEmail
            : "",
          adminimspin: res.data.data.student_imspin
            ? res.data.data.student_imspin
            : "",
          // txtpassword: password,
        };
        // Sending Cookie to CMS
        const cookies = new Cookies();
        const EncryptedLoginInfo = CryptoJS.AES.encrypt(
          JSON.stringify(loginInfo),
          "secret key 123"
        ).toString();
        cookies.set("loginInfo", EncryptedLoginInfo);
        localStorage.setItem("loginInfo", EncryptedLoginInfo);
        dispatch(
          setCurrentHeaderData(
            LoginDetails.isMentor
              ? res.data.data.headerModuleData.filter(
                  (headerItem) => headerItem.name.toLowerCase() !== "mypage"
                )
              : res.data.data.headerModuleData.filter(
                  (headerItem) => headerItem.name.toLowerCase() !== "myplan"
                )
          )
        );
        //TODO  TO BE REMOVED AFTER  OTP MODAL IS LINKED
        history.push("/home");
      },
      onError: () => {
        // setIsLoginError(true);
        setIsLoginClicked(false);
      },
    }
  );
  const handleClick = (e) => {
    e.preventDefault();
    setIsLoginClicked(true);
  };
  const handleSaveAndContinue = () => {
    setIsSaveandContinueClicked(true);
  };

  const setNewPasswd = () => {
    return loginAPI_apirequest({
      url: `${endpoint.createNewPassword.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentEmail: verificationDetails.studentEmail,
        studentImsPin: verificationDetails.studentImsPin,
        enterNewPassword: newPassword,
      },
    });
  };
  useQuery([endpoint.createNewPassword.key], setNewPasswd, {
    enabled: !!isSaveandContinueClicked,
    retry: false,
    onSuccess: () => {
      alert("Password created successfully");
      window.location.reload(false);
    },
  });
  React.useEffect(() => {
    if (LoginDetails?.student_imspin) {
      history.push("/home");
    }
  }, []);

  return (
    <div className="flex font-ibm">
      <div className="w-70% max-h-screen">
        <img
          className="w-[100%] h-[100%]"
          src={loginImage}
          alt="login-image"
          loading="lazy"
        />
        <div className="w-[60%] h-[50%] relative bottom-80 left-24">
          {/* <img className="w-[100%] " src={maskImage} alt="mask-image" loading="lazy"/> */}
          <div className="h-auto relative bottom  space-y-5">
            {/* <button className="bg-white text-black text-base font-medium px-8 py-2 rounded-xl ml-10">
              Sign Up
            </button> */}

            <section className="w-[350px] ml-10">
              <Swiper
                slidesPerView={1}
                pagination={true}
                autoplay
                virtual
                itemType="fractionx"
              >
                {swiperData.map((item) => (
                  <SwiperSlide key={item.id} className="space-y-5  mb-8">
                    <h1 className="text-4xl text-white  font-extralight">
                      {item.title}
                    </h1>
                    <p className="text-lg text-white font-light">{item.para}</p>
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>
          </div>
        </div>
      </div>
      <section className="w-[40%] h-auto">
        {/* <div className="w-[100%]"> */}
        <div className="grid grid-cols-1 place-content-center justify-items-center py-5">
          <div className="justify-start w-[80%]">
            <img
              className="w-20 h-12 -ml-3"
              src={imslogo}
              alt="ims-logo"
              loading="lazy"
            />
            <div className="space-y-5 font-ibm grid grid-cols-1 justify-center align-middle py-12 mt-4">
              <div>
                {!isgetOTPClicked ? (
                  <div>
                    <span className="text-xl font-medium">
                      Enter your registered Email ID or IMS Pin to receive an
                      OTP
                    </span>
                    <form className="grid grid-cols-1 space-y-3">
                      <input
                        value={mobileNumberOrEmail}
                        onChange={(e) => setMobileNumberOrEmail(e.target.value)}
                        type="text"
                        className="border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                        placeholder="Registered Email-Id/ IMS Pin"
                      />
                    </form>
                    <div className="flex flex-col ">
                      {isLoginLoading ? (
                        <Loader />
                      ) : (
                        <OtpModalTrigger
                          isgetOTPClicked={isgetOTPClicked}
                          setIsgetOTPClicked={setIsgetOTPClicked}
                          mobileNumberOrEmail={mobileNumberOrEmail}
                          setDetails={setVerificationDetails}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <span className="text-xl font-medium">
                      Create New Password
                    </span>
                    <form
                      className="grid grid-cols-1 space-y-3"
                      onSubmit={handleClick}
                    >
                      <input
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        type="text"
                        className={`border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                        placeholder="Enter New Password ${
                          newPassword !== confirmPassword
                            ? "border-2 border-red-500 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                            : ""
                        }`}
                      />
                      <input
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type="password"
                        className={`border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                        placeholder="Enter New Password ${
                          newPassword !== confirmPassword
                            ? "border-2 border-red-500 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                            : ""
                        }`}
                        placeholder="Re-Enter New Password"
                      />
                      {newPassword !== confirmPassword && (
                        <div className="flex">
                          <img
                            src={CancelIcon}
                            alt="cancel"
                            className="w-4 h-4 mt-2"
                            loading="lazy"
                          />
                          <p className="text-red-500 text-sm font-normal mt-2">
                            Entered Password and confirm password does not
                            match, Try Again
                          </p>
                        </div>
                      )}

                      <div className="flex flex-col ">
                        {isLoginLoading ? (
                          <Loader />
                        ) : (
                          <button
                            disabled={
                              newPassword && confirmPassword ? false : true
                            }
                            onClick={handleSaveAndContinue}
                            className={`w-full px-24 py-3 rounded-xl text-white text-lg font-medium mt-16 ${
                              newPassword && confirmPassword != ""
                                ? "bg-primary-200"
                                : "bg-[#8E9EAB]"
                            } `}
                          >
                            Save and Continue
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Login;
