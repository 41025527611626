import React, { useState } from "react";
import propTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";
import {
  clearLoginData,
  loginDetails,
  setIsParent,
} from "../../../redux/features/login/LoginSlice";
import SelectProgramModal from "./selectCourse/SelectProgramModal";
import { useDispatch, useSelector } from "react-redux";
import ProfileDropdownMenuItem from "./ProfileDropdownMenuItem";
import localforage from "localforage";
import profileImage from "../../../assets/images/pfp_new.png";
import { useHistory } from "react-router-dom";

const ProfileDropDown = ({ item, menuText = "" }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [showCourseModal, setShowCourseModal] = useState(false);
  const course_id = useSelector((state) => state.login.course_id);
  const courseId = useSelector((state) => state.login.courseId);
  const course_name = useSelector((state) => state.login.course_name);
  const variantId = useSelector((state) => state.login.variantId);
  const LoginDetails = useSelector(loginDetails);
  const isCmsLoginAccess = LoginDetails.cmsLoginAccess;
  const dispatch = useDispatch();

  let onHoverProfile = () => {
    setOpen(!open);
  };

  // Function to remove the dynamically added elements
  function removeAddedElements() {
    // Get the references to the dynamically added elements
    const cssLink = document.querySelector(
      'link[href="//www.imsindia.com/WBM/css/ubm.css?ver=2.68"]'
    );
    const script1 = document.querySelector(
      'script[src="//www.imsindia.com/WBM/js/ubm-jsonp.js?ver=2.68"]'
    );
    const script2 = document.querySelector(
      'script[src="//in.fw-cdn.com/31292575/608260.js"]'
    );
    
    // Remove the elements from the DOM
    if (cssLink) {
      
      cssLink.parentNode.removeChild(cssLink);
    }

    if (script1) {
      script1.parentNode.removeChild(script1);
    }

    if (script2) {
      script2.parentNode.removeChild(script2);
    }
  }

  const handleClick = (id) => {
    // refer header data for id's
    if (id === 3) {
      setShowCourseModal(true);
      setOpen(false);
    }
    if (id === 4) {
      //logout
      let isAdminLogin = LoginDetails?.isAdminLogin;
      localStorage.clear();
      localforage.clear();
      dispatch(setIsParent(false));
      dispatch(clearLoginData());
      // Get the references to the dynamically added elements

      removeAddedElements();
      isAdminLogin ? history.push("/support") : history.push("/");
    }
  };

  return (
    <>
      <div className="relative inline-block text-left ml-4 font-ibm">
        <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
          <div>
            <button
              type="button"
              className="inline-flex justify-center items-center w-full rounded-md text-sm font-medium text-gray-700  focus:outline-none"
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
            >
              <img
                width={24}
                height={24}
                className=" w-6 h-6 rounded-md ml-2 mt-1.5 cursor-pointer "
                src={profileImage}
                // src={menuIcon !="" ? menuIcon : profileImage}
                alt="User Image"
                onClick={() => onHoverProfile()}
                loading="lazy"
              />

              {menuText && (
                <p
                  className={`font-medium text-base font-ibm text-secondary-600 cursor-pointer transition duration-300 ease-in-out transform ${
                    window.location.pathname == "/"
                      ? "hover:text-primary-200 focus:text-primary-200"
                      : "py-3 px-5 hover:font-medium focus:font-medium hover:border-b-[3px]  border-secondary-100 hover:border-primary-200 focus: border-b-2 focus:border-primary-200"
                  }`}
                  onClick={() => onHoverProfile()}
                >
                  {menuText}
                </p>
              )}
            </button>
          </div>

          {open && (
            <div
              style={{ boxShadow: " 0px 4px 10px rgba(33, 37, 41, 0.1)" }}
              className=" absolute right-0 mt-2 w-56 pl-3 py-3 rounded-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-labelledby="menu-button"
              tabIndex="-1"
            >
              {item.map(
                (item) =>
                  (isCmsLoginAccess || item.id !== 5) && (
                    <ProfileDropdownMenuItem
                      key={item.id}
                      id={item.id}
                      src={item.src}
                      path={item.path}
                      title={item.title}
                      handleClick={handleClick}
                    />
                  )
              )}
            </div>
          )}
        </OutsideClickHandler>
      </div>
      {showCourseModal && (
        <SelectProgramModal
          setShowCourseModal={setShowCourseModal}
          course_id={course_id}
          courseId={courseId}
          course_name={course_name}
          variantId={variantId}
        />
      )}
    </>
  );
};

ProfileDropDown.propTypes = {
  item: propTypes.array,
  menuIcon: propTypes.string,
  menuText: propTypes.string,
};

export default ProfileDropDown;
