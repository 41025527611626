import React, { useState } from "react";
import AttendanceRatio from "./AttendanceRatio";
import Classes from "./Classes";
import AttendanceCalendar from "./AttendanceCalendar";
import Header from "../../../web/studentPage/StudentPageHeader";
import Footer from "../../global/footer/Footer";
import { apirequest_liveAPI } from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import ProfileSection from "../../studentPage/studentProfile/ProfileSection";
import { useLocation } from "react-router-dom";

function AttendanceMain() {
  const paramsQuery = useParamsQuery();
  const [allFilterState, setAllFilterState] = useState(true);
  const [missedClassFilterState, setMissedClassFilterState] = useState(true);
  const [attendedClassFilterState, setAttendedClassFilterState] =
    useState(true);
  const stdImsPin = paramsQuery.get("studentImsPin");
  const login = useSelector(loginDetails);
  const userData = useSelector((state) => state.prelogin.profileDetails);
  function useParamsQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const fetchClasses = () => {
    return apirequest_liveAPI({
      url: `${endpoint.getStudentAttendanceData.addr}`,
      method: "post",
      data: {
        mentorImsPin: login.student_imspin,
        studentImsPin: stdImsPin,
      },
    });
  };

  const { data, isLoading } = useQuery(
    [endpoint.calendar_filters.key],
    () => fetchClasses(),
    {
      // enabled: !!courseId,
      select: (res) => res.data.data,
      retry: false,
    }
  );

  return (
    <div>
      <Header />
      <div className="mx-14">
        <ProfileSection userData={userData} />
      </div>

      <div className="mt-12 px-14 font-ibm">
        <h1 className="mb-8 text-2xl font-medium">Overall Attendance</h1>
        <div className="flex mb-[72px] ">
          <AttendanceRatio
            attendance={data?.attendance?.attended}
            totalDays={data?.attendance?.totalClasses}
            percent={null}
            isPercentage={false}
            isLoading={isLoading}
          />
          <AttendanceRatio
            attendance={null}
            totalDays={null}
            percent={data?.attendance?.percentage}
            isPercentage={true}
            isLoading={isLoading}
          />
        </div>
        {data && (
          <AttendanceCalendar
            events_data={{
              attendedClasses: data.attendedClasses,
              missedClasses: data.missedClasses,
            }}
            allFilterState={allFilterState}
            setAllFilterState={setAllFilterState}
            missedClassFilterState={missedClassFilterState}
            setMissedClassFilterState={setMissedClassFilterState}
            attendedClassFilterState={attendedClassFilterState}
            setAttendedClassFilterState={setAttendedClassFilterState}
          />
        )}
        {data?.missedClasses?.length > 0 && missedClassFilterState && (
          <Classes
            classData={data.missedClasses.sort(function (a, b) {
              return new Date(b.createdTs) - new Date(a.createdTs);
            })}
            type="Missed Classes"
            marginBottom={true}
          />
        )}
        {data?.attendedClasses?.length > 0 && attendedClassFilterState && (
          <Classes
            classData={data.attendedClasses.sort(function (a, b) {
              return new Date(b.createdTs) - new Date(a.createdTs);
            })}
            type="Attended Classes"
          />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default AttendanceMain;
