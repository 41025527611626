import React from "react";
import close from "../../../assets/icons/close-white.svg";
import { returnGradient } from "./utils";
import propTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentActiveSechuleViewPopup } from "../../../redux/features/calendar/calendarSlice";
import { endpoint } from "../../../API/config";
import {apirequest_liveAPI} from "../../../API/api";
import { useQuery } from "react-query";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment";
import Loader from "../global/loader/Loader";
function EventDetails({ event, attendance }) {
  
  const dispatch = useDispatch();
  const profileDetails = useSelector(state => state.prelogin.profileDetails);
  const LoginDetails = useSelector(state => state.login.loginDetails);
  const getupdateRecordings = () => {
    return apirequest_liveAPI({
      url: `${endpoint.updateRecordings.addr}`,
      method: "put",
      data:{
        email:LoginDetails.studentEmail,
        first_name:profileDetails.firstName,
        last_name:profileDetails.lastName,
        batch_name:event.event_details.batchName,
        module_number:event.event_details.moduleNumber,
        class_title:event.event_details.classTitle,
        class_date:moment(event.event_details.classDate).format("DD-MM-YYYY")+" "+event.event_details.classTime,
        action:"approve",
        mappingId:event?.event_details.mappingId.toString()
      }
    });
  };
  
  const {refetch,isLoading} = useQuery(endpoint.updateRecordings.key,getupdateRecordings,{
    enabled:false,
    onSuccess: res => {
      if(!res?.data?.status){
        alert(res.data.message);
      }
      else{
        window.open(res.data.share_url);
      }

    },
    onError: () => {
      alert("Something Went Wrong!");
    }
  }); 

  function relocate(link){
    if(link !== ""){
      window.open(event.link);
    } 
    else refetch();
    // alert("Invalid link");
  }
  return (
    <div className="rounded-2xl overflow-hidden shadow-outer w-80 max-w-xs bg-white z-50">
      {/* header */}
      <OutsideClickHandler
        onOutsideClick={() => dispatch(setCurrentActiveSechuleViewPopup(null))}>
        <div
          className={`flex items-center justify-between py-3 px-6 ${
            attendance ? "bg-gradient-to-b" : "bg-gradient-to-r"
          }  ${returnGradient(event?.moduleType || event?.type, true)} `}>
          <p className="text-white text-lg font-ibm font-medium whitespace-nowrap ">
            {event?.name?.length ??
            event?.sessionName?.length ??
            event?.classTitle?.length < 28
              ? event.name ?? event.sessionName ?? event.classTitle
              : (event.name ?? event.sessionName ?? event.classTitle)
                .slice(0, 28)
                .concat(" ...")}
          </p>
          <img
            className="cursor-pointer"
            src={close}
            alt="closeIcon"
            onClick={() => dispatch(setCurrentActiveSechuleViewPopup(null))}
            loading="lazy"
          />
        </div>
        <div className="px-6 py-5 bg-white">
          <p className="mb-2">Date: {event?.start.format("Do MMM YYYY")}</p>
          <p className="mb-2">
            Time: {event?.start.format("h.mm A")} to{" "}
            {event?.end.format("h.mm A")}{" "}
          </p>
          {event?.mentorName ? <p className="mb-2">Mentor: {event?.mentorName}</p> :""}
          <p className="mb-4">Venue: {event?.venue}</p>
          {!attendance && event.event_details?.mode?.toLowerCase() !== "offline" ? (!isLoading ? <button
            onClick={() => relocate(event?.link)}
            className="py-4 bg-primary-200 w-full rounded-2xl text-white text-base font-medium font-ibm  ">
            {event.ctaName}
          </button>:<Loader/>):""}
        </div>
      </OutsideClickHandler>
    </div>
  );
}

EventDetails.propTypes = {
  event: propTypes.object,
  attendance: propTypes.bool,
};

export default EventDetails;
