import React, { useState, useEffect } from "react";
import Footer from "../../../global/footer/Footer";
import MentorFeedBack from "../../../global/mentorFeedBack/MentorFeedBack";
import Header from "../../../header/Header";


import { Tab } from "@headlessui/react";
import { apirequest_myplan } from "../../../../../API/api";
import { endpoint } from "../../../../../API/config";
import { useQuery } from "react-query";

import {loginDetails } from "../../../../../redux/features/login/LoginSlice";
import {  useSelector } from "react-redux";
import Loader from "../../../global/loader/Loader";

const tabOptions = [
  {
    id:1,
    name:"All",
    type:"All"
  },

  {
    id:2,
    name:"LIVE",
    type:"Live",
  },
  {
    id:3,
    //Jira 1816
    name:"Upcoming",
    type:"UpComing",
  },
  {
    id:4,
    name:"Awaiting Feedback",
    type:"Completed"
  }
];




const MyPlan = () => {
  const LoginDetails = useSelector(loginDetails);

  const [isOpen, setIsOpen] = useState(false);
  const [dashboardData,setDashboardData]=useState([]);
  // const [dashboardData,setdashboardData]=useState([]);

  //Request to fetch Booking data API
  const fetchMentorDashboardApi = () => {
    return apirequest_myplan({
      url: `${endpoint.mentorDashboard.addr}`,
      method: "post",
 
      headers: {
        "access-token": LoginDetails.token
      },
      data: {
        imspin: LoginDetails.student_imspin
      },
    });
  };
  const handleImageClick = x => {
    setIsOpen(x);
  };

  // API for student data
  const {refetch:refetchBookingData,isLoading} = useQuery(
    `${endpoint.mentorDashboard.key}`,
    fetchMentorDashboardApi,
    {
      retry:false,
      onSuccess:(res)=>{
        
        setDashboardData([...res.data.data]);
        // setdashboardData(res.data.data);
      }
    }
  );

  useEffect(() => {}, [isOpen]);

  // const handleTabClick = (name,type)=>{
  //   if(type.toLowerCase() === "all"){
  //     setdashboardData(dashboardData);
  //   }
  //   //Checking if slot is not cancelled before listing
  //   else if(name?.toLowerCase()==="awaiting feedback")
  //   { 
  //     dashboardData?.filter(item=>(item.isAwaitingFeedBack && !item.isCancelled));
  //     setdashboardData(dashboardData?.filter(item=>item.isAwaitingFeedBack && !item.isCancelled));
  //   }
  //   else{
  //     setdashboardData(dashboardData?.filter(item=>item.classStatus===type && !item.isCancelled));
  //   }
  // };
  // 
  return (
    <div>
      <Header />
      <Tab.Group>
        <Tab.List className="flex flex-row space-between items-center space-x-3 text px-10 mt-10">
          {tabOptions.map((item) => (
            <Tab
              key={item.id}
              className={({ selected }) =>
                `${
                  selected
                    ? "bg-[#1dadf8] text-white p-3 rounded-2xl shadow-lg"
                    : "bg-white text-black p-3 shadow-lg rounded-2xl"
                } font-ibm font-normal text-lg rounded-2xl px-6 items-center h-14 outline-none`
              }
              // onClick={()=>{handleTabClick(item.name,item.type);}}
            >
              {item.name}
            </Tab>
          ))}
        </Tab.List>
        { isLoading ? (
          <Loader />
        ) :
          (<div className="mt-8 mx-14 shadow-lg pt-9 pb-14 rounded-2xl">
          
            <Tab.Panels>
              <Tab.Panel >
                {
                  dashboardData?.length>0 ? dashboardData?.map((item) => (
                    <MentorFeedBack
                      key={item.bookingId}
                      bookingId={item.bookingId}
                      title={item.sessionName}
                      description={item.description}
                      isLiveNow={item.isLiveNow}
                      date={item.date}
                      time={item.time}
                      place={item.venue}
                      mode={item.mode}
                      bookingStatus={item.status}
                      reasonForBooking={item.reasonForBooking}
                      hangoutLink={item.hangoutLink}
                      feedback={item.feedback}
                      feedBackModifiedDate={item.feedBackModifiedDate}
                      studentName={item.studentName}
                      studentImsPin={item.studentImsPin}
                      classStatus={item.classStatus}
                      course={item.course}
                      mentorAction={item.action}
                      attachments={item.attachments}
                      onImageClick={handleImageClick}
                      refetchBookingData={refetchBookingData}
                      isMentorDasboard={true}
                      isCancelled={item.isCancelled}
                      isAwaitingFeedBack={item.isAwaitingFeedBack}
                      attendance={item.attendance}
                    />
                  )):<p className="ml-10 mt-10 text-red-500 text-xl">No Record Found</p>
                } 
              </Tab.Panel>
              <Tab.Panel>
                {
                  dashboardData?.filter(itm=>itm.classStatus==="Live" && !itm.isCancelled)?.length>0 ? dashboardData?.filter(itm=>itm.classStatus==="Live" && !itm.isCancelled).map((item) => (
                    <MentorFeedBack
                      key={item.bookingId}
                      bookingId={item.bookingId}
                      title={item.sessionName}
                      description={item.description}
                      isLiveNow={item.isLiveNow}
                      date={item.date}
                      time={item.time}
                      place={item.venue}
                      mode={item.mode}
                      bookingStatus={item.status}
                      reasonForBooking={item.reasonForBooking}
                      hangoutLink={item.hangoutLink}
                      feedback={item.feedback}
                      feedBackModifiedDate={item.feedBackModifiedDate}
                      studentName={item.studentName}
                      studentImsPin={item.studentImsPin}
                      classStatus={item.classStatus}
                      course={item.course}
                      mentorAction={item.action}
                      attachments={item.attachments}
                      onImageClick={handleImageClick}
                      refetchBookingData={refetchBookingData}
                      isMentorDasboard={true}
                      isCancelled={item.isCancelled}
                      isAwaitingFeedBack={item.isAwaitingFeedBack}
                      attendance={item.attendance}
                    />
                  )):<p className="ml-10 mt-10 text-red-500 text-xl">No Record Found</p>
                } 
              </Tab.Panel>
              <Tab.Panel>
                {
                  dashboardData?.filter(itm=>itm.classStatus==="UpComing" && !itm.isCancelled)?.length>0 ? dashboardData?.filter(itm=>itm.classStatus==="UpComing" && !itm.isCancelled).map((item) => (
                    <MentorFeedBack
                      key={item.bookingId}
                      bookingId={item.bookingId}
                      title={item.sessionName}
                      description={item.description}
                      isLiveNow={item.isLiveNow}
                      date={item.date}
                      time={item.time}
                      place={item.venue}
                      mode={item.mode}
                      bookingStatus={item.status}
                      reasonForBooking={item.reasonForBooking}
                      hangoutLink={item.hangoutLink}
                      feedback={item.feedback}
                      feedBackModifiedDate={item.feedBackModifiedDate}
                      studentName={item.studentName}
                      studentImsPin={item.studentImsPin}
                      classStatus={item.classStatus}
                      course={item.course}
                      mentorAction={item.action}
                      attachments={item.attachments}
                      onImageClick={handleImageClick}
                      refetchBookingData={refetchBookingData}
                      isMentorDasboard={true}
                      isCancelled={item.isCancelled}
                      isAwaitingFeedBack={item.isAwaitingFeedBack}
                      attendance={item.attendance}
                    />
                  )):<p className="ml-10 mt-10 text-red-500 text-xl">No Record Found</p>
                } 
              </Tab.Panel>
              <Tab.Panel>
                {
                  dashboardData?.filter(itm=>(itm.isAwaitingFeedBack && !itm.isCancelled))?.length>0 ? dashboardData?.filter(itm=>(itm.isAwaitingFeedBack && !itm.isCancelled))?.map((item) => (
                    <MentorFeedBack
                      key={item.bookingId}
                      bookingId={item.bookingId}
                      title={item.sessionName}
                      description={item.description}
                      isLiveNow={item.isLiveNow}
                      date={item.date}
                      time={item.time}
                      place={item.venue}
                      mode={item.mode}
                      bookingStatus={item.status}
                      reasonForBooking={item.reasonForBooking}
                      hangoutLink={item.hangoutLink}
                      feedback={item.feedback}
                      feedBackModifiedDate={item.feedBackModifiedDate}
                      studentName={item.studentName}
                      studentImsPin={item.studentImsPin}
                      classStatus={item.classStatus}
                      course={item.course}
                      mentorAction={item.action}
                      attachments={item.attachments}
                      onImageClick={handleImageClick}
                      refetchBookingData={refetchBookingData}
                      isMentorDasboard={true}
                      isCancelled={item.isCancelled}
                      isAwaitingFeedBack={item.isAwaitingFeedBack}
                      attendance={item.attendance}
                    />
                  )):<p className="ml-10 mt-10 text-red-500 text-xl">No Record Found</p>
                } 
              </Tab.Panel>
            </Tab.Panels>
          </div>)}
      </Tab.Group>
      <Footer />
    </div>
  );
};

export default MyPlan;
