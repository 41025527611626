import React from "react";

import BannerSlider from "./bannerSlider";
import Programmes from "./programmes";
import ArticlesSlider from "./articlesSlider";
import SchoolEvents from "./schoolEvents";
import Header from "../../header/Header";
import Footer from "../../global/footer/Footer";

import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config";
import { fetchSchoolProgrammes } from "../apiFunctions";
import { apirequest_schoolsAPI } from "../../../../API/api";

import { useSelector , useDispatch } from "react-redux";
import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import Loader from "../../global/loader/Loader";
import Gallery from "../../global/gallery/Gallery";
import {setShowGallery} from "../../../../redux/features/schools/SchoolSlice";

function SchoolDetails() {
  const LoginDetails = useSelector(loginDetails);
  const dispatch = useDispatch();
  const courseId = useSelector((state) => state.login.courseId);
  // 
  const { schoolId } = useSelector((state) => state.school.schoolDetails);
  const { showGallery } = useSelector((state) => state.school);
  

  //Query to fetch the school data =========>
  const {
    data: schoolData,
    isSuccess,
    isLoading,
  } = useQuery([endpoint.getSchoolDetails.key, schoolId], () =>
    fetchSchoolProgrammes(schoolId, LoginDetails, courseId)
  );

  // Query to fetch selection criteria data ====>
  const { data: selectionCriteriaData, isSuccess: SelectionCriteriaFetched } =
    useQuery(
      [endpoint.GetSelectionCriteria.key, courseId],
      () =>
        apirequest_schoolsAPI({
          url: endpoint.GetSelectionCriteria.addr,
          method: "post",
          headers: {
            "access-token": LoginDetails.token,
          },
          data: {
            courseId: courseId,
          },
        }),
      {
        select: (res) => res.data.data[0],
      }
    );
  
  const handleGallery = () => {
    dispatch(setShowGallery(false));
  };

  return (
    <>
      {showGallery ? (
        <Gallery images={schoolData?.data?.data[0].schoolImages} onImageClick={handleGallery} isFromSchool={true}/>
      ) : (
        <>
          <Header />
          <div className="px-14 font-ibm ">
            {isLoading && <Loader />}
            {isSuccess && <BannerSlider imageData={schoolData?.data.data[0]} />}
            {isSuccess && SelectionCriteriaFetched && (
              <>
                <Programmes
                  data={schoolData?.data.data[0]}
                  selectionCriteriaData={selectionCriteriaData}
                />
              </>
            )}

            {isSuccess && (
              <>
                <ArticlesSlider data={schoolData?.data?.data[0].articles} />
                <SchoolEvents data={schoolData?.data?.data[0].events} />
              </>
            )}
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default SchoolDetails;
