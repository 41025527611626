import React from "react";
import {
  handleChangeProfessionalCertificate,
  handleObjectChangeEducation,
  setProfileDetails,
} from "../../../../redux/features/prelogin/preloginSlice";
import { loginAPI_apirequest } from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import propTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import SimpleDropDown from "../SimpleDropDown";
import DeleteIcon from "../../../../assets/icons/delete.svg";
// import { useQuery } from "react-query";

function ProfessionalCertificate({ item, index }) {
  const dispatch = useDispatch();
  
  // const options = [
  //   { id: 1, topic: "CGPA" },
  //   { id: 2, topic: "Percentage" },
  // ];
  const educationalFields = useSelector(state => state.prelogin.profileDetails);
  let certificateDetails =
    educationalFields.educationalDetails.ProfessionalCertification;
  const handleProfessionalCertificate = (e, id, attrName) => {
    if(attrName=="CGPA_x_y" || attrName=="Marks"){
      if(attrName=="CGPA_x_y"){
        let payload = {
          id,
          value: 0,
          attrName:"Marks",
        };
        dispatch(handleObjectChangeEducation(payload));
      }
      else {
        let payload = {
          id,
          value: 0,
          attrName:"CGPA_x_y",
        };
        dispatch(handleObjectChangeEducation(payload));
      }
    }
    
    let payload = {
      id,
      value: e.target.value,
      attrName,
    };
    dispatch(handleObjectChangeEducation(payload));
  };
  const fetchDegreeDropdown = () => {
    return loginAPI_apirequest({
      url: `${endpoint.getDegree.addr}`,
      method: "get",
    });
  };
  const { data: degree } = useQuery(
    `${endpoint.getDegree.key}`,
    fetchDegreeDropdown,
  );
  const handleDropdownChange = (id, dropdownName, attrName) => {
    let payload = {
      id,
      value: dropdownName,
      attrName,
    };
    dispatch(handleChangeProfessionalCertificate(payload));
  };
  const handleDeleteProfessionalCerificate = (
    id,
    attrName,
    attrValue,
    objInd,
  ) => {
    let formObj = JSON.parse(JSON.stringify(educationalFields));
    let { educationalDetails } = formObj;

    if (!id) {
      let updatedObj = {
        ...educationalDetails,
        ProfessionalCertification: certificateDetails.filter(
          (item, ind) => ind != objInd,
        ),
      };
      dispatch(
        setProfileDetails({
          ...educationalFields,
          educationalDetails: updatedObj,
        }),
      );
    } else {
      let newObjInd =
        formObj.educationalDetails.ProfessionalCertification.findIndex(obj => {
          if (obj.Educational_Details_id == id) {
            return obj;
          }
        });

      formObj.educationalDetails.ProfessionalCertification[newObjInd].isDelete =
        attrValue;
      dispatch(setProfileDetails({ ...formObj }));
    }
  };
  return (
    item.isDelete !== "1" && (
      <>
        <div className="flex space-x-[25rem] my-5 justify-between ">
          <p className="font-medium text-lg ">Professional Certification</p>
        </div>
        {/* course Name */}
        <div className="flex space-x-2 relative mb-3 ">
          {/* <input
            type="text"
            autoComplete="off"
            placeholder="Course*"
            className={
              "border text-sm border-secondary-300 rounded-xl w-full p-4 focus:outline-none"
            }
            name="pcCourse"
            value={item.Course === "" ? null : item.Course}
            onChange={event => {
              handleProfessionalCertificate(event, index, "Course");
            }}
          /> */}
          {index > 0 && (
            <div
              onClick={() => {
                handleDeleteProfessionalCerificate(
                  item?.Educational_Details_id,
                  "isDelete",
                  "1",
                  index,
                );
              }}
              className="w-12 h-12 cursor-pointer rounded-full shadow-outer flex justify-center items-center absolute -right-14 ">
              <img className="w-5 h-5" src={DeleteIcon} alt="del" />
            </div>
          )}
        </div>
        {/* <input
          type="text"
          autoComplete="off"
          placeholder="College name*"
          className={
            "border text-sm border-secondary-300 rounded-xl w-full p-4 mb-3 focus:outline-none"
          }
          name="pcCollegeName"
          value={item.School_College === "" ? null : item.School_College}
          onChange={event => {
            handleProfessionalCertificate(event, index, "School_College");
          }}
        /> */}
        <div className="grid grid-cols-2 gap-4 mb-3 ">
          {/* Fetching "degree" value in PC, if user has selected from the dropdown option */}
          <div>
            <SimpleDropDown value={item.Degree || "Degree"}>
              <SimpleDropDown.Options>
                {degree?.data?.data
                  .filter(
                    entry =>  
                      entry.qualificationType ===
                        "Professional Certification" ||
                      entry.qualificationType === null,
                  )
                  .map(option => (
                    <SimpleDropDown.Option
                      key={option.qualificationId}
                      option={option.qualificationName}
                      setValue={() => {
                        handleDropdownChange(
                          index,
                          option.qualificationName,
                          "Degree",
                        );
                      }}
                    />
                  ))}
              </SimpleDropDown.Options>
            </SimpleDropDown>
          </div>

          {/* year of completion Professional Certificate */}
          <div>
            <input
              type="number"
              autoComplete="off"
              placeholder="Year of Completion*"
              className={
                "border text-sm border-secondary-300 rounded-xl w-full p-4 focus:outline-none"
              }
              name="pcYear"
              value={item.Expected_Actual_Yr_of_completion}
              onChange={event => {
                handleProfessionalCertificate(
                  event,
                  index,
                  "Expected_Actual_Yr_of_completion",
                );
              }}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            {/* Fetching "Grade_Type" value in PC, if user has selected from the dropdown options */}
            {/* <SimpleDropDown value={item?.Grade_Type || "Grade"}>
              <SimpleDropDown.Options>
                {options.map(option => (
                  <SimpleDropDown.Option
                    key={option.id}
                    option={option.topic}
                    setValue={() =>
                      handleDropdownChange(index, option.topic, "Grade_Type")
                    }
                  />
                ))}
              </SimpleDropDown.Options>
            </SimpleDropDown> */}
          </div>
          <div>
            {/* <input
              type="number"
              min="0"
              max="100"
              step="0.1"
              autoComplete="off"
              placeholder="Grade*"
              className={
                "border text-sm border-secondary-300 rounded-xl w-full p-4 focus:outline-none"
              }
              name="pcGradeSystem"
              value={item?.Grade_Type=="CGPA"?item.CGPA_x_y:item.Marks}
              onChange={event => {
                handleProfessionalCertificate(event, index, item?.Grade_Type=="CGPA"?"CGPA_x_y":"Marks");
              }}
            /> */}
          </div>
        </div>
      </>
    )
  );
}

ProfessionalCertificate.propTypes = {
  item: propTypes.object,
  index: propTypes.number,
  setProfessionalCertificationSelected: propTypes.func,
};

export default ProfessionalCertificate;
