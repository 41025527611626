import React, { useEffect, useState } from "react";
import Header from "../../header/Header";
import Footer from "../../global/footer/Footer";
import Breadcrumb from "../../global/breadcrumb/Breadcrumb";

import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config";
import { fetchCourseArticleEventData } from "../apiFunctions";
import ArticleCard from "./ArticleCard";
import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import { useSelector } from "react-redux";
import DropDown from "../../global/dropDown/DropDown";
import Loader from "../../global/loader/Loader";
import MoreArticleCard from "./MoreArticleCard";
import { filterBasedOnDates } from "../filterEventsArticles";
import ArticlesEventsSearch from "../../global/search/ArticlesAndEventsSearch";
import TablePagination from "./TablePagination";

export const Landing = () => {
  const LoginDetails = useSelector(loginDetails);
  const courseId = useSelector((state) => state.login.courseId);
  // const student_ImsPin=LoginDetails.student_imspin;

  const dropdownOptions = [
    { id: 1, topic: "All" },
    { id: 2, topic: "Today" },
    { id: 3, topic: "Last Week" },
    { id: 4, topic: "Last Month" },
    { id: 5, topic: "Last Year" },
  ];

  const [selectedDropdownValue, setSelectedDropdownValue] = useState(
    dropdownOptions[0]["topic"]
  );

  const {
    data: articlesData,
    refetch,
    isSuccess,
    isLoading,
    isError,
  } = useQuery(
    [endpoint.getCourseArticleEventData.key, courseId],
    () => fetchCourseArticleEventData(courseId, LoginDetails),
    {
      select: (x) => x.data.data[0]?.article,
      enabled: !!courseId,
    }
  );

  const [currentPage, setCurrentPage] = useState(1);
  /* Limit Data To Show to 10 */
  const dataPerPage = 5;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };
  
  const [filterArticlesData, setFilteredArticlesData] = useState(articlesData);

  useEffect(() => {
    filterBasedOnDates(
      selectedDropdownValue,
      setFilteredArticlesData,
      articlesData,
      "activeDate",
      setCurrentPage
    );
  }, [selectedDropdownValue]);

  return (
    <div className="">
      <Header />
      <div className="my-4">
        <Breadcrumb />
      </div>
      {isLoading && <Loader />}
      {isError && (
        <p className="font-semibold text-base text-red-600 flex justify-center">
          No Record found
        </p>
      )}

      {isSuccess && (
        <div className="px-14">
          <div className="flex mb-16">
            {articlesData
              .filter((article) => article.isPinned === "1")
              .map((item) => (
                <ArticleCard
                  key={item.articleId}
                  articleId={item.articleId}
                  articleName={item?.articleName}
                  description={item?.description}
                  readTime={item?.duration}
                  activeDate={item?.activeDate}
                  thumbnailLink={item?.thumbnailLink}
                  isBookmark={item?.isBookmark}
                  articleRefetch={refetch}
                  htmlContent={item?.htmlContent}
                />
              ))}
          </div>

          <p className="font-medium text-2xl">More Articles</p>
          <div className="flex justify-between">
            <DropDown
              options={dropdownOptions}
              text="Show"
              setDropdownValue={setSelectedDropdownValue}
            />
            <ArticlesEventsSearch
              data={articlesData}
              placeholder="Search by Article Name"
              setFunction={setFilteredArticlesData}
              filterName="articleName"
              filterId="articleId"
              payloadKey="Articles"
              setCurrentPage={setCurrentPage}
            />
          </div>
          {filterArticlesData?.length > 0 ? <div>{
            filterArticlesData
              .slice(indexOfFirstItem, indexOfLastItem)
              .map((item) => (
                <MoreArticleCard
                  key={item?.articleId}
                  articleId={item?.articleId}
                  articleName={item?.articleName}
                  description={item?.description}
                  createdTs={item?.createdTs}
                  date={item?.date}
                  thumbnailLink={item?.thumbnailLink}
                  isBookmark={item?.isBookmark}
                  htmlContent={item?.htmlContent}
                  articleRefetch={refetch}
                  tag={item.tags}
                />
              ))
          }
          <div className="courseTable__footer mx-auto mb-7">
            {/* {getBlogData && getBlogData.data.data && ( */}
            <TablePagination
              data={filterArticlesData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              paginate={paginate}
              dataPerPage={dataPerPage}
            />
            {/* )} */}
          </div>
          </div>
            : (
              <p className="text-base font-semibold text-red-500 flex justify-center">
                No Record Found
              </p>
            )}
          
        </div>
      )}
      <Footer />
    </div>
  );
};
